import { render, staticRenderFns } from "./kindergarten.vue?vue&type=template&id=747b76ff&scoped=true"
import script from "./kindergarten.vue?vue&type=script&lang=js"
export * from "./kindergarten.vue?vue&type=script&lang=js"
import style0 from "./kindergarten.vue?vue&type=style&index=0&id=747b76ff&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "747b76ff",
  null
  
)

export default component.exports