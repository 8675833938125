<!-- 查看幼儿园详情 -->
<template>
  <el-dialog
    title="查看幼儿园信息"
    :visible.sync="detailShow"
    width="50%"
    @click="closeDialog"
    :destroy-on-close="true"
    :close-on-click-modal="false"
  >
    <div style="padding-left: 4%">
      <a-descriptions title="" layout="vertical">
        <a-descriptions-item label="幼儿园名称">
          <div class="item">{{ this.name }}</div></a-descriptions-item
        >
        <a-descriptions-item label="所属网格">
          {{ this.gridAddress }}</a-descriptions-item
        >
        <a-descriptions-item label="办学类型">{{
          this.type
        }}</a-descriptions-item>
        <a-descriptions-item label="园长姓名">
          {{ this.managerName }}
        </a-descriptions-item>
        <a-descriptions-item label="联系方式">{{
          this.contact
        }}</a-descriptions-item>
        <a-descriptions-item label="位置信息">
          {{ this.address }}
        </a-descriptions-item>
        <a-descriptions-item label="特殊儿童信息"> 李三 </a-descriptions-item>
        <a-descriptions-item label="周边交通类型">
          {{ this.transport }}
        </a-descriptions-item>
        <a-descriptions-item label="安全隐患"
          >{{ this.safetyDesc }}
        </a-descriptions-item>
        <a-descriptions-item label="场所经度"
          >{{ this.longitude }}
        </a-descriptions-item>
        <a-descriptions-item label="场所纬度">
          {{ this.latitude }}
        </a-descriptions-item>
      </a-descriptions>
      <div style="display: flex; justify-content: flex-end">
        <a-button type="primary" @click="onSubmit"> 保存 </a-button>
        <a-button style="margin-left: 20px" @click="closeDialog">
          取消
        </a-button>
      </div>
    </div>
  </el-dialog>
</template>
  <script>
import api from "./api";

export default {
  components: {},
  data() {
    return {
      api,
      detailShow: false,
      id: "",
      name: "",
      gridAddress: null,
      managerName: "",
      contact: "",
      transport: "",
      safetyDesc: "",
      latitude: "",
      longitude: "",
      type: "",
      address: "",
    };
  },
  watch: {},
  computed: {},
  created() {},
  mounted() {},
  methods: {
    // 打开弹窗
    openModal(record = {}) {
      this.detailShow = true;
      this.id = record.id;
      console.log(this.id);
      this.$nextTick(() => {
        this.getData();
      });
    },
    //查看
    async getData() {
      const res = await api.getById(this.id);
      this.name = res.data.name;
      this.gridAddress = res.data.gridAddress;
      this.type = res.data.type;
      this.managerName = res.data.managerName;
      this.contact = res.data.contact;
      this.address = res.data.address;
      this.transport = res.data.transport;
      this.safetyDesc = res.data.safetyDesc;
      this.latitude = res.data.latitude;
      this.longitude = res.data.longitude;
    },
    //提交
    async onSubmit() {
      // 提交成功后关闭弹窗
      this.refreshTable();
      this.closeDialog();
    },
    //关闭弹窗
    closeDialog() {
      this.detailShow = false;
    },
    //刷新代码
    refreshTable() {
      this.$emit("refreshTable");
      console.log(11111);
    },
  },
};
</script>
      
  <style lang="less" scoped>
/deep/.ant-modal {
  top: 0;
}
/deep/.ant-descriptions-item-content {
  display: table-cell;
  font-weight: 400;
  font-size: 14px;
  color: #2d3138;
  line-height: 18px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
/deep/.ant-descriptions-item-label {
  font-weight: 400;
  font-size: 14px;
  color: #7d8da6;
  line-height: 18px;
  text-align: left;
}
</style>
      